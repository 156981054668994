<template>
  <section style="min-height: 1000px;">
    <div class="container-fluid">
      <uspCartao titulo="Usuários">
        <template slot="corpo">
          <button class="btn btn-primary" @click.prevent="novo()">Novo usuário</button>
          <div class="table-responsive">
            <table class="table mt-3">
              <thead>
                <tr>
                  <th scope="col" style="width: 20%;">Número USP</th>
                  <th scope="col" style="width: 50%;">Nome</th>
                  <th scope="col" style="width: 20%;">Perfil</th>
                  <th scope="col" style="width: 10%;" class="text-right">
                    <i v-show="listando" class="fas fa-sync-alt"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in lista">
                    <td>{{ item.codpes }}</td>
                    <td>{{ item.nompes }}</td>
                    <td>{{ item.nomnivseg }}</td>
                    <td class="text-right">
                      <a href="#" title="Desativar" v-show="!excluindo" @click.prevent="excluir(item)"><i class="fas fa-trash"></i></a>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </uspCartao>
      <uspModal titulo="Usuário" ref="Usuario" :maxWidth="windowWidth > 768 ? '50%' : '100%'">
        <template slot="body">
          <form name="form" @submit.prevent="salvar()">
            <input type="submit" style="display: none;" name="submit"></input>
            <div class="form-group">
              <label>Número USP</label>
              <input type="number" v-model="objeto.codpes" class="form-control" required>
            </div>
            <div class="form-group">
              <label>Perfil</label>
              <select class="form-control" v-model="objeto.numnivseg" required>
                <option value=""></option>
                <option value="1">GRS</option>
                <option value="2">Gestor de arquivo</option>
              </select>
            </div>
          </form>
        </template>
        <template slot="footer">
            <button type="button" class="btn btn-secondary" :disabled="salvando" @click="fecharModal('Usuario')">Fechar</button>
            <button type="button" class="btn btn-primary" :disabled="salvando" data-dismiss="modal" @click.prevent="validarParaSalvar()">Salvar</button>
        </template>
      </uspModal>
    </div>
  </section>
</template>
<script>
import UspDriveServico from "../dominio/UspDriveServico";
const api = UspDriveServico.build({});
export default {
  name: "UspDrivePastas",
  data() {
    return {
        lista: [],
        objeto: {},
        listando: false,
        salvando: false,
        excluindo: false,
        windowWidth: window.innerWidth,
    };
  },
  methods: {
    listar() {
      const self = this;
      self.lista = [];
      self.listando = true;
      self.$forceUpdate();
      api.listarUsuarioUspDrive({}).then(function(lista) {
        self.lista = lista;
      }).catch(function(e) {
        alert(e.mensagem);
      }).finally(function() {
        self.listando = false;
      });
    },
    novo() {
      const self = this;
      self.objeto = {};
      self.$refs['Usuario'].openModal();
    },
    validarParaSalvar() {
      document.form.submit.click();
    },
    salvar() {
      const self = this;
      self.salvando = true;
      self.$forceUpdate();
      api.criarUsuarioUspDrive(self.objeto).then(function() {
        self.fecharModal('Usuario');
        self.listar();
      }).catch(function(e) {
        alert(e.mensagem);
      }).finally(function() {
        self.salvando = false;
      });
    },
    excluir(item) {
      const self = this;
      if (confirm("Confirma desativação deste usuário?")) {
        self.excluindo = true;
        self.$forceUpdate();
        api.desativarUsuarioUspDrive(item).then(function() {
          self.listar();
        }).catch(function(e) {
          alert(e.mensagem);
        }).finally(function() {
          self.excluindo = false;
        });
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    fecharModal(ref){
      this.$refs[ref].closeModal();
    }
  },
  beforeDestroy() { 
    window.removeEventListener('resize', self.onResize); 
  },
  mounted() {
    const self = this;
    self.listar();
    self.$nextTick(() => {
      window.addEventListener('resize', self.onResize);
    });
  }
};
</script>
<style>
</style>
