<template>
  <section style="min-height: 1000px;">
    <div class="container-fluid">
      <uspCartao titulo="Pasta">
        <template slot="corpo">
          <form>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label font-weight-bold">Número do processo no SAJ</label>
              <div class="col-sm-8">
                <input type="text" readonly class="form-control-plaintext" :value="dados.numprosaj">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label font-weight-bold">Título do PAD</label>
              <div class="col-sm-8">
                <input type="text" readonly class="form-control-plaintext" :value="dados.nomptaarqudr">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label font-weight-bold">Cadastrada em</label>
              <div class="col-sm-8">
                <input type="text" readonly class="form-control-plaintext" :value="dados.dtacad">
              </div>
            </div>
          </form>
        </template>
      </uspCartao>
      <uspCartao titulo="Arquivos">
        <template slot="corpo">
          <button class="btn btn-primary" @click.prevent="novo()">Novo arquivo</button>
          <div class="table-responsive">
            <table class="table mt-3">
              <thead>
                <tr>
                  <th scope="col" style="width: 35%;">Arquivo</th>
                  <th scope="col" style="width: 35%;">Descrição</th>
                  <th scope="col" style="width: 20%;">Data de criação</th>
                  <th scope="col" style="width: 10%;" class="text-right">
                    <i v-show="listando" class="fas fa-sync-alt"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                  <tr v-for="item in lista">
                      <td>{{ item.nomarqudr_fs }}</td>
                      <td>{{ item.dscarqudr }}</td>
                      <td>{{ item.dtacad }}</td>
                      <td class="text-right">
                        <a href="#" v-show="!fazendoDownload" @click.prevent="download(item)"><i class="fas fa-download"></i></a>
                        <a class="ml-3" v-show="!excluindoArquivo && podeGravar" @click.prevent="excluirArquivo(item)" href="#"><i class="fas fa-trash"></i></a>
                      </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </template>
      </uspCartao>
      <uspCartao titulo="Permissões" v-show="podeGravar">
        <template slot="corpo">
          <div class="alert alert-warning" role="alert">
            <i class="fas fa-exclamation-triangle"></i>&nbsp;&nbsp;Aqui ficarão estabelecidas todas as pessoas que terão acesso à vista dos arquivos desta pasta incluída, 
            então é muito importante que seja incluída nessa lista de permissões somente as pessoas necessárias para a visualização dos arquivos neste processo.
          </div>
          <button class="btn btn-primary" @click.prevent="novaPermissao()">Nova permissão</button>
          <div class="table-responsive">
            <table class="table mt-3">
              <thead>
                <tr>
                  <th scope="col" style="width: 20%;">Número USP</th>
                  <th scope="col" style="width: 50%;">Nome</th>
                  <th scope="col" style="width: 20%;">Adicionado em</th>
                  <th scope="col" style="width: 10%;" class="text-right">
                    <i v-show="listandoPermissao" class="fas fa-sync-alt"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                  <tr v-for="item in listaPermissao">
                      <td>{{ item.codpespmd }}</td>
                      <td>{{ item.nompes }}</td>
                      <td>{{ item.dtacad }}</td>
                      <td class="text-right">
                        <a v-show="!excluindoPermissao" @click.prevent="excluirPermissao(item)" href="#"><i class="fas fa-trash"></i></a>
                      </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </template>
      </uspCartao>
      <uspModal titulo="Arquivo" ref="Arquivo" :maxWidth="windowWidth > 768 ? '50%' : '100%'">
        <template slot="body">
          <div class="alert alert-warning" role="alert">
            <i class="fas fa-exclamation-triangle"></i>&nbsp;&nbsp;São aceitos os mais variados tipos de arquivo: documentos, PDFs, imagens, vídeos, áudios etc., desde que não ultrapassem o limite de tamanho de arquivo (100 MB).
          </div>
          <form name="form" @submit.prevent="salvar()">
            <input type="submit" style="display: none;" name="submit"></input>
            <div class="form-group">
              <label>Arquivo</label>
              <input type="file" id="file" class="form-control" required>
            </div>
            <div class="form-group">
              <label>Descrição</label>
              <input type="text" v-model="objeto.dscarqudr" class="form-control" required>
            </div>
          </form>
        </template>
        <template slot="footer">
            <button type="button" class="btn btn-secondary" :disabled="salvando" @click="fecharModal('Arquivo')">Fechar</button>
            <button type="button" class="btn btn-primary" :disabled="salvando" data-dismiss="modal" @click="validarParaSalvar()">Salvar</button>
        </template>
      </uspModal>
      <uspModal titulo="Permissão" ref="Permissao" :maxWidth="windowWidth > 768 ? '50%' : '100%'">
        <template slot="body">
          <form name="formPermissao" @submit.prevent="buscarPessoa()">
            <input type="submit" style="display: none;" name="submit"></input>
            <div class="form-group">
              <label>Número USP ou início do nome ou nome completo</label>
              <input type="text" class="form-control" v-model="objetoPermissao.busca" required>
            </div>
            <button class="btn btn-primary" :disabled="listandoUsuario" @click="buscarPessoa()">Buscar</button>
          </form>
          <div class="table-responsive" v-show="listaUsuario.length > 0">
            <table class="table mt-3">
              <thead>
                <tr>
                  <th scope="col" style="width: 20%;">Número USP</th>
                  <th scope="col" style="width: 70%;">Nome</th>
                  <th scope="col" style="width: 10%;" class="text-right"></th>
                </tr>
              </thead>
              <tbody>
                  <tr v-for="item in listaUsuario">
                      <td>{{ item.codpes }}</td>
                      <td>{{ item.nompes }}</td>
                      <td class="text-right">
                        <a title="Adicionar" v-show="!salvandoPermissao" @click.prevent="salvarPermissao(item)" href="#"><i class="fas fa-plus"></i></a>
                      </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </template>
        <template slot="footer">
            <button type="button" class="btn btn-secondary" :disabled="salvandoPermissao" @click="fecharModal('Permissao')">Fechar</button>
        </template>
      </uspModal>
    </div>
  </section>
</template>
<script>
import UspDriveServico from "../dominio/UspDriveServico";
const api = UspDriveServico.build({});
export default {
  name: "UspDriveArquivos",
  data() {
    return {
        listando: false,
        lista: [],
        objeto: {},
        salvando: false,
        dados: {},
        listandoPermissao: false,
        listaPermissao: [],
        objetoPermissao: {},
        salvandoPermissao: false,
        excluindoPermissao: false,
        excluindoArquivo: false,
        fazendoDownload: false,
        podeGravar: false,
        listandoUsuario: false,
        listaUsuario: [],
        windowWidth: window.innerWidth
    };
  },
  methods: {
    novo() {
      const self = this;
      self.objeto = {};
      self.$refs['Arquivo'].openModal();
    },
    listar() {
      const self = this;
      self.lista = [];
      self.listando = true;
      self.$forceUpdate();
      api.listarArquivoUspDrive(self.$route.params).then(function(lista) {
        self.listando = false;
        self.lista = lista;
      }).catch(function(e) {
        alert(e.mensagem);
      }).finally(function() {
        self.listando = false;
      });
    },
    validarParaSalvar() {
      document.form.submit.click();
    },
    salvar() {
      const self = this;
      self.salvando = true;
      api.salvarArquivoUspDrive(Object.assign({}, self.$route.params, self.objeto), document.getElementById("file").files[0]).then(function() {
        self.fecharModal("Arquivo");
        self.listar();
      }).catch(function(e) {
        alert(e.mensagem);
      }).finally(function() {
        self.salvando = false;
      });
    },
    download(item) {
      const self = this;
      self.fazendoDownload = true;
      api.obterArquivoUspDrive(item).then((resp) => {
        const url = URL.createObjectURL(resp);
        const a = document.createElement('a');
        a.href = url;
        a.download = item.nomarqudr_fs;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        self.fazendoDownload = false;
      });
    },
    listarPermissao() {
      const self = this;
      self.listaPermissao = [];
      self.listandoPermissao = true;
      self.$forceUpdate();
      api.listarPermissaoUspDrive(self.$route.params).then(function(lista) {
        self.listandoPermissao = false;
        self.listaPermissao = lista;
      }).catch(function(e) {
        alert(e.mensagem);
      }).finally(function() {
        self.listandoPermissao = false;
      });
    },
    novaPermissao() {
      const self = this;
      self.objetoPermissao = {};
      self.listaUsuario = [];
      self.$refs['Permissao'].openModal();
    },
    buscarPessoa() {
      const self = this;
      self.listandoUsuario = true;
      self.listaUsuario = [];
      self.$forceUpdate();
      api.buscarPessoaUspDrive(self.objetoPermissao).then(function(lista) {
        self.listaUsuario = lista;
      }).catch(function(e) {
        alert(e.mensagem);
      }).finally(function() {
        self.listandoUsuario = false;
      });
    },
    salvarPermissao(item) {
      const self = this;
      self.salvandoPermissao = true;
      api.inserirPermissaoUspDrive(Object.assign({}, self.$route.params, {codpespmd: item.codpes})).then(function() {
        self.fecharModal("Permissao");
        self.listarPermissao();
      }).catch(function(e) {
        alert(e.mensagem);
      }).finally(function() {
        self.salvandoPermissao = false;
      });
    },
    excluirPermissao(item) {
      const self = this;
      if (confirm("Confirma exclusão desta permissão?")) {
        self.excluindoPermissao = true;
        self.$forceUpdate();
        api.removerPermissaoUspDrive(item).then(function() {
          self.listarPermissao();
        }).catch(function(e) {
          alert(e.mensagem);
        }).finally(function() {
          self.excluindoPermissao = false;
        });
      }
    },
    excluirArquivo(item) {
      const self = this;
      if (confirm("Confirma exclusão deste arquivo?")) {
        self.excluindoArquivo = true;
        self.$forceUpdate();
        api.removerArquivoUspDrive(item).then(function() {
          self.listar();
        }).catch(function(e) {
          alert(e.mensagem);
        }).finally(function() {
          self.excluindoArquivo = false;
        });
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    fecharModal(ref){
      this.$refs[ref].closeModal();
    }
  },
  beforeDestroy() { 
    window.removeEventListener('resize', self.onResize); 
  },
  mounted() {
    const self = this;
    api.obterPastaUspDrive(self.$route.params).then(function(lista) {
      if (lista.length > 0) {
        self.dados = lista[0];
        api.verificarPermissaoSoftplan({processoSAJ: self.dados.numprosaj, numeroUSP: self.dados.codpescad}).then(function(r) {
          if (r.result) {
            self.listar();
            api.verificarPermissaoUspDrive(Object.assign({}, {tipo: "G"}, self.$route.params)).then(function(lista) {
              self.podeGravar = true;
              self.listarPermissao();
            }).catch(function() {
              self.podeGravar = false;
            });
          } else {
            alert("Usuário sem permissão para acessar os dados deste processo!");
          }
        }).catch(function() {
          alert("Usuário sem permissão para acessar os dados deste processo!");
        });
      }
    }).catch(function(e) {
      alert(e.mensagem);
    });
    self.$nextTick(() => {
      window.addEventListener('resize', self.onResize);
    });
  }
};
</script>
<style>
</style>
