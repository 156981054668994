<template>
    <section style="min-height: 1000px;">
      <div class="container-fluid">
        <uspCartao :titulo="'Processo ' + numprosaj">
          <template slot="corpo">
            <div v-if="buscando">Buscando processo...</div>
            <div v-if="!buscando && lista.length == 0">Processo n&atilde;o encontrado.</div>
          </template>
        </uspCartao>
      </div>
    </section>
  </template>
  <script>
  import UspDriveServico from "../dominio/UspDriveServico";
  const api = UspDriveServico.build({});
  export default {
    name: "UspDriveProcesso",
    data() {
      return {
        buscando: true,
        numprosaj: "",
        lista: []
      };
    },
    methods: {
    },
    mounted() {
      const self = this;
      self.numprosaj = self.$route.params.numprosaj;
      api.buscarProcessoUspDrive({numprosaj: self.numprosaj}).then(function(lista) {
        self.lista = lista;
        self.buscando = false;
        if (self.lista.length > 0) {
            self.$router.push('/usp-drive/pasta/' + lista[0].codptaarqudr);
        }
      });
    }
  };
  </script>
  <style>
  </style>
  