<template>
  <section style="min-height: 1000px;">
    <div class="container-fluid">
      <uspCartao titulo="Pastas">
        <template slot="corpo">
          <button class="btn btn-primary" @click.prevent="novo()">Nova pasta</button>
          <div class="table-responsive">
            <table class="table mt-3">
              <thead>
                <tr>
                  <th scope="col" style="width: 30%;">Processo SAJ</th>
                  <th scope="col" style="width: 40%;">Título do PAD</th>
                  <th scope="col" style="width: 20%;">Cadastrada em</th>
                  <th scope="col" style="width: 10%;" class="text-right">
                    <i v-show="listando" class="fas fa-sync-alt"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in lista">
                    <td>{{ item.numprosaj }}</td>
                    <td>{{ item.nomptaarqudr }}</td>
                    <td>{{ item.dtacad }}</td>
                    <td class="text-right">
                      <a href="#" title="Alterar"  v-show="item.pode_alterar == 'S'" class="mr-3" @click.prevent="alterar(item)"><i class="fas fa-edit"></i></a>
                      <a href="#" title="Arquivos" @click.prevent="listarArquivo(item)"><i class="fas fa-list"></i></a>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </uspCartao>
      <uspModal titulo="Pasta" ref="Pasta" :maxWidth="windowWidth > 768 ? '50%' : '100%'">
        <template slot="body">
          <form name="form" @submit.prevent="salvar()">
            <input type="submit" style="display: none;" name="submit"></input>
            <div class="form-group">
              <label>Processo SAJ</label>
              <input type="text" v-model="objeto.numprosaj" :disabled="objeto.codptaarqudr" class="form-control" required>
            </div>
            <div class="form-group">
              <label>Título do PAD</label>
              <input type="text" v-model="objeto.nomptaarqudr" class="form-control" required>
            </div>
          </form>
        </template>
        <template slot="footer">
            <button type="button" class="btn btn-secondary" :disabled="salvando" @click="fecharModal('Pasta')">Fechar</button>
            <button type="button" class="btn btn-primary" :disabled="salvando" data-dismiss="modal" @click="validarParaSalvar()">Salvar</button>
        </template>
      </uspModal>
    </div>
  </section>
</template>
<script>
import UspDriveServico from "../dominio/UspDriveServico";
const api = UspDriveServico.build({});
export default {
  name: "UspDrivePastas",
  data() {
    return {
        lista: [],
        listando: false,
        objeto: {},
        salvando: false,
        windowWidth: window.innerWidth
    };
  },
  methods: {
    novo() {
      const self = this;
      self.objeto = {};
      self.$refs['Pasta'].openModal();
    },
    alterar(item) {
      const self = this;
      self.objeto = Object.assign({}, item);
      self.$refs['Pasta'].openModal();
    },
    listar() {
      const self = this;
      self.lista = [];
      self.listando = true;
      self.$forceUpdate();
      api.listarPastaUspDrive({}).then(function(lista) {
        self.lista = lista;
      }).catch(function(e) {
        alert(e.mensagem);
      }).finally(function() {
        self.listando = false;
      });
    },
    validarParaSalvar() {
      document.form.submit.click();
    },
    salvar() {
      const self = this;
      self.salvando = true;
      if (self.objeto.codptaarqudr) {
        api.alterarPastaUspDrive(self.objeto).then(function() {
          self.fecharModal("Pasta");
          self.listar();
        }).catch(function(e) {
          alert(e.mensagem);
        }).finally(function() {
          self.salvando = false;
        });
      } else {
        api.verificarPermissaoSoftplan({processoSAJ: self.objeto.numprosaj, numeroUSP: self.$store.state.auth.usuarioLogado.split("-")[0]}).then(function(r) {
          if (r.result) {
            api.inserirPastaUspDrive(Object.assign({}, self.objeto, {'hasvrface': r.hash})).then(function() {
              self.fecharModal("Pasta");
              self.listar();
            }).catch(function(e) {
              alert(e.mensagem);
            }).finally(function() {
              self.salvando = false;
            });
          } else {
            alert("Usuário sem permissão para criar pasta para este processo!");
            self.salvando = false;
          }
        }).catch(function(e) {
          alert(e.mensagem);
        }).finally(function() {
          self.salvando = false;
        });
      }
    },
    listarArquivo(item) {
      const self = this;
      self.$router.push('/usp-drive/pasta/' + item.codptaarqudr);
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    fecharModal(ref){
      this.$refs[ref].closeModal();
    }
  },
  beforeDestroy() { 
    window.removeEventListener('resize', self.onResize); 
  },
  mounted() {
    const self = this;
    self.listar();
    self.$nextTick(() => {
      window.addEventListener('resize', self.onResize);
    });
  }
};
</script>
<style>
</style>
