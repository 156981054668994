import { PortalServico } from '@/utils/';

/**
 * @type {UspDriveServico}
 */
export default class UspDriveServico extends PortalServico {

    verificarPermissaoSoftplan(params) {
        return this.post("/wsportal/softplan/validarUsuarioProcesso", params);
    }
    verificarPermissaoUspDrive(params) {
        return this.post("/uspproc/executar/uspdrive/verificarPermissaoUspDrive", params);
    }
    buscarPessoaUspDrive(params) {
        return this.post("/uspproc/listar/uspdrive/buscarPessoaUspDrive", params);
    }
    buscarProcessoUspDrive(params) {
        return this.post("/uspproc/listar/uspdrive/buscarProcessoUspDrive", params);
    }

    listarPastaUspDrive(params) {
        return this.post("/uspproc/listar/uspdrive/listarPastaUspDrive", params);
    }
    inserirPastaUspDrive(params) {
        return this.post("/uspproc/executar/uspdrive/inserirPastaUspDrive", params);
    }
    alterarPastaUspDrive(params) {
        return this.post("/uspproc/executar/uspdrive/alterarPastaUspDrive", params);
    }
    obterPastaUspDrive(params) {
        return this.post("/uspproc/listar/uspdrive/obterPastaUspDrive", params);
    }

    listarPermissaoUspDrive(params) {
        return this.post("/uspproc/listar/uspdrive/listarPermissaoUspDrive", params);
    }    
    inserirPermissaoUspDrive(params, file) {
        return this.post('/uspproc/executar/uspdrive/inserirPermissaoUspDrive', params);
    }
    removerPermissaoUspDrive(params) {
        return this.post('/uspproc/executar/uspdrive/removerPermissaoUspDrive', params);
    }

    listarArquivoUspDrive(params) {
        return this.post("/uspproc/listar/uspdrive/listarArquivoUspDrive", params);
    }    
    salvarArquivoUspDrive(params, file) {
        return this.post('/uspproc/upload/uspdrive/salvarArquivoUspDrive', params, [file] );
    }
    removerArquivoUspDrive(params) {
        return this.post('/uspproc/executar/uspdrive/removerArquivoUspDrive', params);
    }
    obterArquivoUspDrive(params) {
        return this.download('/uspproc/download/uspdrive/obterArquivoUspDrive?codarqudr=' + params.codarqudr);
    }

    listarUsuarioUspDrive(params) {
        return this.post("/uspproc/listar/uspdrive/listarUsuarioUspDrive", params);
    }
    criarUsuarioUspDrive(params) {
        return this.post("/uspproc/executar/uspdrive/criarUsuarioUspDrive", params);
    }
    desativarUsuarioUspDrive(params) {
        return this.post("/uspproc/executar/uspdrive/desativarUsuarioUspDrive", params);
    }
}
